// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-dato-cms-post-slug-js": () => import("./../../../src/pages/posts/{DatoCmsPost.slug}.js" /* webpackChunkName: "component---src-pages-posts-dato-cms-post-slug-js" */),
  "component---src-pages-recipe-js": () => import("./../../../src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-pages-recipes-dato-cms-recipe-slug-js": () => import("./../../../src/pages/recipes/{DatoCmsRecipe.slug}.js" /* webpackChunkName: "component---src-pages-recipes-dato-cms-recipe-slug-js" */)
}

